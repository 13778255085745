.rmnw__footer-wrapper {
  background-color: rgb(24, 24, 24);
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
}

.rmnw__footer-main {
  display: flex;
  align-self: flex-start;
  width: 55%;
  height: 100%;
}

.rmnw__footer-logo {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 5px 0 0 15px;
}

.rmnw__footer-logo img {
  width: 120px;
  height: 80px;
}

.rmnw__footer-logo h2 {
  color: #f5f5f5;
  width: 140px;
  font-family: var(--font-family2);
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  text-align: left;
  margin-left: 3px;
}

.rmnw__footer-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rmnw__footer-cta h1 {
  color: white;
  font-family: var(--font-family2);
  background: var(--gradient-accent);
  background-clip: text;

  font-weight: 500;
  align-self: flex-start;
  text-align: left;
  width: 100%;
  margin-top: -75px;
}

.rmnw__footer-cta_button {
  align-self: center;
  width: 250px;
  height: 45px;
  border: solid 3px #e1e1e1;
  border-radius: 7px;
  background: var(--gradient-accent);
  background-clip: text;
  color: rgba(255, 255, 255, 0);
  font-weight: 800;
  font-size: 18px;

  margin: 25px 0 0 7vw;
}

.rmnw__footer-cta_button p {
  margin-top: 7px;
}

.rmnw__footer-cta_button p:hover {
  transform: translate(0, -1px);
}

.rmnw__footer-cta_button p:active {
  transform: translate(1px, 1px);
}
.rmnw__footer-cta_button button:active {
  transform: translate(1px, 1px);
}

.rmnw__footer-nav {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: flex-end;
  height: 100%;
  flex: 1;
  align-items: center;
}
.rmnw__footer-nav_accent-line {
  height: 60%;
  width: 3px;
  background: var(--gradient-accent);
  justify-self: center;
  margin-left: 55px;
}

.rmnw__footer-nav_accent-line2 {
  height: 60%;
  width: 3px;
  background: var(--gradient-accent);
  justify-self: center;
  margin-left: 35px;
}

.rmnw__footer-nav-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  height: 100%;
  margin: 0 1vw;
}
.rmnw__footer-nav-list p {
  color: #e1e1e1;
  width: 76px;
  font-size: 18px;
  text-align: center;
}

.rmnw__footer-nav-list2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 150px;
  height: 100%;
  margin: 0 1vw;
  gap: 45px;
}

.rmnw__footer-nav-list2 svg {
  height: 37px;
  width: 37px;
  align-self: center;
  color: rgb(117, 230, 230);
}

.rmnw__footer-nav-list2 p {
  color: #e1e1e1;
  width: 76px;
  font-size: 18px;
  text-align: center;
}

.footer_nav-link {
  text-decoration: none;
}
.footer_nav-link p:hover {
  background: var(--gradient-accent);
  background-clip: text;
  color: rgba(255, 255, 255, 0);
  transform: translate(0, -1px);
}

.footer_nav-link p:active {
  transform: translate(1px, 1px);
}
.contact_link p:hover {
  background: var(--gradient-accent);
  background-clip: text;
  color: rgba(255, 255, 255, 0);
  transform: translate(0, -1px);
  overflow: visible;
}
.c2 .contact_link p {
  width: 200px;
}

.contact_link p:active {
  transform: translate(1px, 1px);
}

.footer_contact-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: 2vw;
}
.c3 p {
  display: none;
  margin-left: 20px;
  font-weight: 700;
}

.footer_contact-container p {
  width: 125px;
}

@media screen and (max-width: 1190px) {
  .rmnw__footer-wrapper {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .rmnw__footer-main {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .rmnw__footer-cta {
    justify-self: center;
    width: 90%;
    margin: 12px 0 10px 2vw;
  }
  .rmnw__footer-cta h1 {
    margin: 32px 0 0 0;
    font-size: 32px;
    line-height: 2.5rem;
    width: 100%;
    text-align: center;
  }

  .rmnw__footer-cta_button {
    margin: 55px 0 0 0;
    width: 170px;
  }
  .rmnw__footer-cta_button p {
    font-size: 18px;
  }

  .rmnw__footer-nav {
    width: 100%;
  }

  .rmnw__footer-nav-list {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-self: flex-end;
    align-self: center;
    gap: 55px 55px;
    justify-content: center;
    margin-bottom: 12px;
  }
  .rmnw__footer-nav-list2 {
    flex-direction: row;
    width: 100%;

    align-self: center;
    gap: 55px 55px;
    justify-content: center;
    margin-bottom: 12px;
  }

  .rmnw__footer-nav {
    justify-content: center;
    flex-direction: column;
  }
  .rmnw__footer-nav_accent-line,
  .rmnw__footer-nav_accent-line2 {
    display: none;
  }

  .rmnw__footer-logo {
    align-self: center;
  }

  .rmnw__footer-logo h2 {
    display: none;
  }
  .footer_contact-container {
    margin: 0 14px 0 10px;
  }
}

@media screen and (max-width: 760px) {
  .rmnw__footer-wrapper {
    height: 100%;
  }

  .rmnw__footer-nav-list {
    gap: 15px 15px;
  }
  .rmnw__footer-nav-list2 {
    gap: 15px 15px;
    flex-direction: column;
    align-items: center;
  }
  .c3 {
    margin-bottom: 20px;
  }
}
