.App {
  text-align: center;
  font-family: "Degular", sans-serif;
  font-weight: 400;
}

.rmnw__navbar-holder {
  display: flex;
  flex-direction: row;
  background-color: black;
  justify-content: center;
  align-items: center;
}
