.rmnw__about-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

}

.rmnw__about-wrapper h1 {
  font-size: 42px;
}

.rmnw__about-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40vw;
  min-height: 340px;
  max-height: 550px;
  background-image: url(../../../public/media/rmnw-about-us-banner-2-tint.png);
  background-size: cover;
  background-position: center;
  margin-top: 120px;
}

.rmnw__about-hero h1 {
  font-size: 120px;
  color: white;
}

.rmnw__about-owners {
  width: 85%;
  max-width: 1000px;
  align-self: center;
  margin-top: 170px;
  margin-bottom: 120px;
  line-height: 2.5rem;
  text-align: left;

}

.rmnw__about-owners h1 {
  text-align: center;
  font-size: 42px;
}

.rmnw__about-owners p {
  margin-top: 75px;
  text-indent: 35px;
  font-size: 22px;
}

.rmnw__about-owners-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.rmnw__about-owners-image-container img {
  width: 40%;
}

.rmnw__about-owners-image-bottom {
 width: 100%;
 display: flex;
 justify-content: center;
 margin-top: 75px;
}
.rmnw__about-owners-image-bottom img {
  width: 33%;
  min-width: 275px;
}

.rmnw__about-crew {
  width: 85%;
  max-width: 1000px;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 120px;
  line-height: 2.5rem;
  text-align: left;

}

.rmnw__about-crew h1 {
  text-align: center;
}

.rmnw__about-crew p {
  margin-top: 75px;
  text-indent: 35px;
  font-size: 22px;
}

.rmnw__about-crew-images {
  width: 100%;
  height: 100%;
}
.rmnw__about-crew-images img {
  width: 100%;

}

.rmnw__about-crew-images-mobile {
  display: none;
}

@media screen and (max-width: 550px) {
  .rmnw__about-crew-collage{
    display: none;
 }
 .rmnw__about-crew-images-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0px 10px;
  align-items: center;
  align-self:center;
}
.rmnw__about-crew-images-mobile img {
  width: 85%;
  margin: 45px 0 15px 0;
}
.rmnw__about-owners-image-container {
  flex-direction: column;
  align-items: center;
  gap: 45px;
}
.rmnw__about-owners-image-container img {
  width: 85%;
}
}