.rmnw__services-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.rmnw__service {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  min-height: 450px;
  text-align: left;
  text-indent: 50px;
}
.rmnw__service h1 {
  margin: 0 0 35px 5vw;
}

.service1,
.service3 {
  background: var(--gradient-box);
  color: rgb(235, 235, 235);
}
.service3 {
  flex-direction: column;
}

.service2 {
  background-color: rgb(242, 242, 242);
  background: var(--gradient-box);
  background-clip: text;
  color: rgba(255, 255, 255, 0);
  font-weight: 500;
  font-family: var(--font-family2);
  justify-content: flex-end;
}

.service_content-wrapper {
  max-width: 75%;
  margin-bottom: 35px;
}

.service_content-wrapper p {
  width: 60vw;
  max-width: 800px;
  line-height: 2rem;
  font-size: 22px;
}

.wrapper1 {
  margin-top: 45px;
  margin-left: 10vw;

}


.wrapper2 {
  margin-right: 10vw;
}

.wrapper3 {
  max-width: 1600px;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper3 p {
  width: 100%;
  max-width: 1600px;
}

.service_image-wrapper {
  width: 25%;
  max-width: 450px;
  height: 22.2vw;
  background-color: rgba(7, 160, 7, 0);
  display: flex;
}

.service_image-wrapper img {
  width: 100%;
  align-self: center;
}
.imgwrap2 {
  justify-self: flex-end;

  margin: 0 6vw 0 3vw;
}

.imgwrap1,
.imgwrap3 {
  justify-self: flex-start;

  margin: 0 3vw 0 6vw;
}
.imgwrap3 {
    width: 55%;
    height: 42.3vw;
    max-width: none;
    min-width: 300px;
  
}


@media screen and (max-width: 1220px) {
  .rmnw__service {
    flex-direction: column;

  }
  .imgwrap2 {
    order: 2;
  }
  .service_image-wrapper {
    width: 55%;
    height: 42.3vw;
    max-width: none;
  }
  .service_content-wrapper {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }
  .service_content-wrapper p {
    width: 100%;
    text-indent: 8px;
  }
}

@media screen and (max-width: 850px) {
  .service_content-wrapper h1 {
    font-size: 28px;
    margin: 45px 0 35px 0;
  }
  .service_content-wrapper p {
    margin-bottom: 45px;
    font-size: 18px;
  }
  .rmnw__service {
    flex-direction: column;
  }
  .rmnw__service {
    height: 100%;
  }
  
}

@media screen and (max-width: 550px) {
  .service_content-wrapper {
    width: 80%;
    margin: 15px 0 32px 0;
    text-align: center;
    align-items: center;
  }
  .rmnw__service h1 {
    margin: 0 0 22px 0;
    text-indent: 0px;
  }
  .rmnw__service p {
    text-align: center;
    margin: 0;
    align-self: center;
  }

  .service_content-wrapper p {
    align-self: center;
  }
}
