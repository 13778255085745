:root {
  margin: 0;

  --font-family1: "Komet", sans-serif;
  --font-family2: "Degular", sans-serif;

  --gradient-text: linear-gradient(
    45deg,
    rgb(0, 28, 89) 0%,
    rgb(59, 116, 169) 56%
  );

  --gradient-box: linear-gradient(
    45deg,
    rgb(21, 37, 56) 0%,
    rgb(6, 56, 101) 94%
  );

  --gradient-accent: linear-gradient(
    45deg,
    rgba(53, 226, 226, 1) 0%,
    rgba(217, 245, 245, 1) 87%
  );
}

body {
  background-color: rgb(243, 243, 238);
  margin: 0;
}

.contact_link {
  text-decoration: none;
}

/* ==== Dark Blue gradient ====

background: rgb(36, 58, 85);
background: -moz-linear-gradient(
  45deg,
  rgba(36, 58, 85, 1) 0%,
  rgba(15, 69, 117, 1) 94%
);
background: -webkit-linear-gradient(
  45deg,
  rgba(36, 58, 85, 1) 0%,
  rgba(15, 69, 117, 1) 94%
);
background: linear-gradient(
  45deg,
  rgba(36, 58, 85, 1) 0%,
  rgba(15, 69, 117, 1) 94%
);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#243a55",endColorstr="#0f4575",GradientType=1);
*/

/* ==== Light blue -> white gradient ==== 
background: rgb(53,226,226);
background: -moz-linear-gradient(45deg, rgba(53,226,226,1) 0%, rgba(217,245,245,1) 87%);
background: -webkit-linear-gradient(45deg, rgba(53,226,226,1) 0%, rgba(217,245,245,1) 87%);
background: linear-gradient(45deg, rgba(53,226,226,1) 0%, rgba(217,245,245,1) 87%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#35e2e2",endColorstr="#d9f5f5"

*/
